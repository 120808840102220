<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <vx-card>
        <div class="vx-row">
          <div class="vx-col">
            <vs-input class="w-full" type="date" v-model="dateCaisse" @change="SelectOperationOfDateSelected()" :max="new Date(Date.now()).toISOString().split('T')[0]"></vs-input>
          </div>
          <div class="vx-col">Fd de caisse: <span class="font-bold">{{moneyFormatter(TotalFondCaisse)}}</span></div>
          <div class="vx-col">Décaissé: <span class="text-danger font-bold">{{moneyFormatter(TotalMontantDecaisse)}}</span></div>
          <div class="vx-col">Encaissé: <span class="text-primary font-bold">{{moneyFormatter(TotalEncaisse)}}</span></div>
          <div class="vx-col">En caisse: <span v-if="TotalEnCaisse >= 0" class="text-primary font-bold">{{moneyFormatter(TotalEnCaisse)}}</span><span v-else class="text-danger font-bold">{{moneyFormatter(TotalEnCaisse)}}</span></div>
        </div>
      </vx-card>
    </div>
    <div class="vx-col w-full mt-4">
      <vx-card class="min-h-screen">
        <vs-table stripe noDataText="Aucune opération" pagination max-items="50" :data="operations">
          <template slot="thead">
            <vs-th sort-key="Date">
              Date
            </vs-th>
            <vs-th>Heure</vs-th>
            <vs-th>Agence</vs-th>
            <vs-th sort-key="pu">Caissier</vs-th>
            <vs-th sort-key="modepaiement">Moyen</vs-th>
            <vs-th sort-key="typeOperation">Opération</vs-th>
            <vs-th sort-key="montant">Montant</vs-th>
            <vs-th >Actions</vs-th>
          </template>

          <template slot-scope="{data}">
            <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                <vs-td>
                  {{tr.dateOperation  | moment("calendar", "July 10 2011")}}
                </vs-td>
                <vs-td>
                  {{tr.createdAt | moment("HH:mm:ss") }}
                </vs-td>
                <vs-td>
                  {{getAgenceById(tr.IdOfficine) ? getAgenceById(tr.IdOfficine).NomOffic : ''}}
                </vs-td>
                <vs-td>
                  {{getUserById(tr.IdUser) ? getUserById(tr.IdUser).nom : ''}} {{getUserById(tr.IdUser) ? getUserById(tr.IdUser).prenoms : ''}}
                </vs-td>
                <vs-td>
                  {{tr.modepaiement}}
                </vs-td>
                <vs-td>
                  {{tr.typeOperation}}
                </vs-td>
                <vs-td v-if="tr.typeOperation === 'Règlement cmde cli'" class="text-primary font-bold">
                  {{tr.montant ? moneyFormatter(tr.montant) : 0}}
                </vs-td>
                <vs-td v-else-if="tr.typeOperation === 'Approvisionnement Caisse Depense'" class="text-danger font-bold">
                  {{tr.montant ? moneyFormatter(tr.montant) : 0}}
                </vs-td>
                <vs-td v-else>
                  {{tr.montant ? moneyFormatter(tr.montant) : 0}}
                </vs-td>
                <vs-td class="flex">
                  <vs-button class="mr-2" color="danger" icon-pack="feather" icon="icon-trash-2" @click="confirmDeleteOperation(tr)" ></vs-button>
                </vs-td>

              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </vx-card>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      dateCaisse: new Date(Date.now()).toISOString().split('T')[0],
      IdOfficine: null,
      idOperation: null
    }
  },
  computed: {
    operations () {
      return this.$store.state.operation.operations
    },
    TotalFondCaisse () {
      return this.$store.getters['operation/TotalFondCaisse']
    },
    TotalEncaisse () {
      return this.$store.getters['operation/TotalEncaisse']
    },
    TotalMontantDecaisse () {
      return this.$store.getters['operation/TotalMontantDecaisse']
    },
    TotalEnCaisse () {
      return ((this.TotalFondCaisse + this.TotalEncaisse) - this.TotalMontantDecaisse)
    }
  },
  methods: {
    moneyFormatter (montant) {
      /* Formatage du calcul */
      const val = (montant / 1).toFixed(0).replace('.', ',')

      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    getAgenceById (id) {
      return this.$store.getters['officine/getOfficineById'](id)
    },
    getUserById (id) {
      return this.$store.getters['userManagement/getUserById'](id)
    },
    SelectOperationOfDateSelected () {
      const payload = {
        selectDate: this.dateCaisse
      }
      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('operation/getOperationAll', payload)
        .then(() => {
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })

    },
    confirmDeleteOperation (data) {
      /* this.IdClient = data.IdClient
      this.NumVente = data.NumVente */
      this.idOperation = data._id

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmation de suppression',
        text: 'Cette action est irréversible',
        accept: this.removeOperation,
        acceptText: 'Supprimer'
      })
    },
    removeOperation () {
      const payload = {
        id: this.idOperation
      }

      this.$vs.loading({
        type: 'sound'
      })

      this.$store.dispatch('operation/removeOperation', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          /* this.IdClient = null
          this.NumVente = null */
          this.idFacture = null
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    getOperationAll () {
      const payload = {
        selectDate: this.dateCaisse
      }

      this.$store.dispatch('operation/getOperationAll', payload)
        .then(() => {

        })
        .catch((err) => {
          console.log(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
      this.IdOfficine = this.activeUserInfos.IdOfficine
      this.IdUser = this.activeUserInfos._id
      /* this.getClientsOfficine(this.IdOfficine) */
    },
    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then(() => {

        })
        .catch((err) => {
          console.log(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    fetchUsers () {
      this.$store.dispatch('userManagement/fetchUsers')
        .then(() => {
        })
        .catch(err => {
          console.error(err)
          this.$vs.notify({
            title: 'error',
            text: `${err.message}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    }
  },
  created () {
    if (!(this.$store.state.officine.officines.length > 0)) this.getOfficines()
    this.activeUserInfo()
    if (!(this.$store.state.userManagement.users.length > 0)) this.fetchUsers()
    this.getOperationAll()
  }
}
</script>
